import { API_ENDPOINT, GetHeaders } from './api'

export const uploadHolerite = async (file, payload) => {
  try {
    const formData = new FormData()
    
    if (!(file instanceof File)) {
      throw new Error('Arquivo inválido')
    }

    // Validar campos obrigatórios do payload
    const requiredFields = ['cnpj', 'tipo-arquivo', 'competencia', 'tipo-holerite'];
    for (const field of requiredFields) {
      if (!payload.payload[field]) {
        throw new Error(`Campo ${field} é obrigatório`);
      }
    }

    formData.append('file', file)
    formData.append('type', payload.type)
    formData.append('payload', JSON.stringify(payload.payload))

    const { headers } = await GetHeaders(true)
    delete headers['Content-Type']

    const response = await fetch(`${API_ENDPOINT}/holerite-generate/upload`, { 
      method: 'POST', 
      body: formData, 
      headers 
    });

    const responseJson = await response.json()
    console.log("Server response", responseJson)
    
    // Verifica primeiro se é limite excedido
    if (responseJson.details?.limitExceeded) {
      return responseJson;
    }
    
    if (!response.ok) {
      const errorMessage = responseJson.message || responseJson.error || 'Erro ao fazer upload do arquivo'
      throw new Error(errorMessage)
    }
    
    return responseJson
  } catch (err) {
    console.error('Erro no upload:', err)
    return { 
      error: true, 
      message: err.message || 'Erro ao processar o arquivo'
    }
  }
} 