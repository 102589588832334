import styled from 'styled-components'  
import { CardContainer } from '../styled';

export const CardHomeChart = styled.div.attrs({ 
})`
    width: 100%;
    min-height: 300px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    & > div {
        width: 100% !important;
        height: 100% !important;
        min-height: inherit;
    }

    rect[fill="#ffffff"]{
        fill: transparent !important;
    }
`;

export const StyledCardContainer = styled(CardContainer)`
    height: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;

    & > div {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    ${CardHomeChart} {
        flex: 1;
        overflow: hidden;
    }
`;

export const CardHomeStatuItem = styled.div.attrs({ 
})`
    flex:1;
    padding: 16px;
    border-radius: 16px;
    box-shadow: 0px 1px 3px ${ props => props.theme.palette.colors.lightshadow };
    background: ${ props => props.theme.palette.colors.white }; 
    border: 1px solid ${ props => props.theme.palette.colors.lightshadow };
    b{
        margin: 0;
        font-size: 14px;
        font-weight: bold;
        color: ${ props => props.theme.palette.primary.main }; 
        min-height: 41px;
        display:flex;
        align-items: center;
        ${ props => props.limited ? `max-width: 55px;` : `` }
    }
    p{
        margin: 6px 0 0;
        font-size: 18px;
        font-weight: bold;
        color: ${ props => props.theme.palette.primary.text }; 
    }
`;