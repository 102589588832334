import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import {
    DropPaycheck,
    DropPaycheckIcon,
    FormActions,
    ListEmployees,
    UnregisteredEmployeesList,
} from "./styled";

import DashboardCardWrapper from "components/Dashboard/Cards/Wrapper";

import DashboardActions from "components/Dashboard/Actions";
import DashboardFormsCore from "../Core";
import DashboardFeedback from "components/Dashboard/Feedback";
import { toast } from "react-toastify";
import { yearOptions } from "utils";
import UploadFile from "components/Form/UploadFile";
import { UploadImage } from "services/api";
import { CoreContext } from "context/CoreContext";
import useEmployee from "hooks/useEmployee";
import { ConfirmationDialog } from "components/Modal";
import { uploadReportIncome } from 'services/report-income-upload';

export default function DashboardFormsRevenueAdd() {

    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const refForm = useRef()
    const { currentCompany, user } = useContext(CoreContext)
    const { createEmployee } = useEmployee()

    const actions = [
        { title: 'Cancelar', outline: true, action: () => history.goBack() },
        { title: 'Salvar', action: () => save(), loadable: true }
    ]

    const [loading, setLoading] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null)
    const [feedback, setFeedback] = useState(null)
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [unregisteredEmployees, setUnregisteredEmployees] = useState([]);
    const [showReplaceModal, setShowReplaceModal] = useState(false);
    const [replaceFile, setReplaceFile] = useState(null);

    const [formItems, setFormItems] = useState([])

    const maskCPF = (cpf) => {
        const digitsOnly = cpf.replace(/\D/g, '');
        return digitsOnly.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }

    const save = async () => {
        try {
            const values = refForm.current.getForm();
            if (!(values['year'])) {
                toast.error("Preencha todos os campos");
                return;
            }
            if (!selectedFile || !(selectedFile instanceof File)) {
                toast.error("Selecione um arquivo válido");
                return;
            }

            setLoading(true);

            const payload = {
                type: "informerendimento",
                payload: {
                    'CNPJ': currentCompany?.cnpj?.replace(/\.|\-|\//g, ''),
                    'Ano Calendario': values['year']
                },
                companyId: currentCompany?.id
            };

            const result = await uploadReportIncome(selectedFile, payload);

            if (result.error) {
                if (result.message?.includes("Informe de rendimento já existe para este ano")) {
                    setShowReplaceModal(true);
                    setLoading(false);
                    return;
                }
                throw new Error(result.message || "Erro ao processar o arquivo");
            }

            if (result.success && result.processInfo?.savedRecords > 0) {
                toast.success("Informe de rendimento processado e cadastrado com sucesso.");
                history.goBack();
            } else {
                if (result.errors?.length > 0) {
                    throw new Error(result.errors[0].message);
                }
                throw new Error(result.message || "Erro ao processar o arquivo, verifique se os dados estão corretos.");
            }
        } catch (error) {
            console.error('Erro durante o salvamento:', error);
            toast.error(error.message || "Ocorreu um erro inesperado durante o processo.");
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        setDialogOpen(false);
    };

    const handleReplaceConfirm = async () => {
        try {
            if (!replaceFile) {
                toast.error("Selecione o novo arquivo");
                return;
            }

            setLoading(true);
            const values = refForm.current.getForm();
            
            const result = await uploadReportIncome(replaceFile, {
                type: "informerendimento",
                payload: {
                    'CNPJ': `${currentCompany?.cnpj}`?.replace(/\.|\-|\//g, ''),
                    'Ano Calendario': `${values['year']}`
                },
                companyId: currentCompany?.id,
                replace: true
            });

            if (result.success) {
                toast.success("Informe de rendimento substituído com sucesso");
                history.goBack();
            } else {
                toast.error(result.message || "Erro ao substituir o informe");
            }
        } catch (error) {
            console.error('Error replacing report:', error);
            toast.error("Erro ao substituir o informe");
        } finally {
            setLoading(false);
            setShowReplaceModal(false);
            setReplaceFile(null);
        }
    };

    const UnregisteredEmployeesContent = () => (
        <UnregisteredEmployeesList>
            {unregisteredEmployees.map((employee, index) => (
                <ListEmployees key={index}>
                    Nome: {employee.nome} <br />
                    CNPJ: {employee.cnpjCpf} <br />
                    CPF: {employee.cpf} <br />
                    Razão social: {employee.razaoSocial}
                </ListEmployees>
            ))}
        </UnregisteredEmployeesList>
    );

    useEffect(() => {
        setFormItems([
            { placeholder: "Ano", ref: "year", options: yearOptions, half: true },
            {
                placeholder: "Informe de rendimento",
                ref: "file",
                custom: selectedFile?.name ?
                    <DropPaycheck onClick={() => setSelectedFile(null)}>{selectedFile?.name} <DropPaycheckIcon close /></DropPaycheck>
                    :
                    <UploadFile forwardRepo onChange={e => setSelectedFile(e)}>
                        <DropPaycheck><DropPaycheckIcon /> Adicionar arquivo </DropPaycheck>
                    </UploadFile>,
                half: true
            }
        ])
    }, [selectedFile])

    return (
        <>
            <DashboardCardWrapper backable title={"Cadastrar informes"} white>
                {feedback ? <DashboardFeedback message={"Informes de rendimento cadastrados com sucesso"} type={"success"} /> : null}
                <DashboardFormsCore formItems={formItems} ref={refForm} />
                <FormActions>
                    <DashboardActions actions={actions} loading={loading} />
                </FormActions>
            </DashboardCardWrapper>

            <ConfirmationDialog
                open={isDialogOpen}
                title="Funcionários Não Cadastrados"
                message="Os seguintes funcionários não estão cadastrados e precisam de atenção:"
                onCancel={handleClose}
                BtnConfirm={false}
                children={<UnregisteredEmployeesContent />}
            />

            <ConfirmationDialog
                open={showReplaceModal}
                title="Substituir Informe de Rendimento"
                message="Já existe um informe de rendimento lançado para este CPF e período. Deseja substituir o arquivo existente?"
                onCancel={() => {
                    setShowReplaceModal(false);
                    setReplaceFile(null);
                }}
                onConfirm={handleReplaceConfirm}
                confirmText="Substituir"
                cancelText="Cancelar"
                loading={loading}
            >
                <UploadFile forwardRepo onChange={e => setReplaceFile(e)}>
                    <DropPaycheck>
                        <DropPaycheckIcon /> 
                        {replaceFile?.name || "Adicionar novo arquivo"}
                    </DropPaycheck>
                </UploadFile>
            </ConfirmationDialog>

        </>
    );
}