import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CoreContext } from "context/CoreContext";
import { exposeStrapiError, normalizeStrapiList } from "utils";
import { GeneratePdf, ReadAll, ReadAllByUploadId, ReadAllForEmployee } from "services/report-incomes";
import moment from "moment";
import { parseDatestringUSBR } from "utils/parsers.js";
import { ReadEmployeeByUserId } from "services/employee.js";
import { toast } from "react-toastify";
import DashboardActions from "components/Dashboard/Actions";
import DashboardCardTabs from "components/Dashboard/Cards/Tabs";
import DashboardCardTable from "components/Dashboard/Cards/Table";

export default function DashboardListsRevenues() {
  const history = useHistory();
  const navigate = (to) => history.push(`/${to}`);
  const { searchExpression, currentCompany, user } = useContext(CoreContext);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [rows, setRows] = useState([]);

  // Ações disponíveis
  const actions = [
    ...(user?.isManager ? [{ title: 'Novo', action: () => navigate("dashboard/revenues/add") }] : [])
  ];

  const actionsSingle = [
    ...(user?.isManager ? [{
      title: "Download em LOTE",
      action: (uploadId) => downloadMassByUploadId(uploadId),
      loadable: true
    }] : []),
    ...(!user?.isManager ? [{
      title: "Download",
      action: (registerId) => downloadSingle(registerId),
      loadable: true
    }] : [])
  ];

  // Definição das colunas baseada no tipo de usuário
  const columns = [
    { title: "Data e hora da criação", ref: "createdAt" },
    ...(user?.isManager ? [
      { title: "Usuário que importou", ref: "importingUser" },
      { title: "Exercício do informe", ref: "exercise" },
      {
        title: "", ref: "",
        renderCell: (row) => {
          const updatedActions = actionsSingle.map(action => ({
            ...action,
            action: () => action.action(row.row.uploadId)
          }));
          return <DashboardActions actions={updatedActions} loading={row.loading} />;
        }
      }
    ] : [
      { title: "Nome", ref: "name" },
      { title: "CPF", ref: "cpf" },
      {
        title: "", ref: "",
        renderCell: (row) => {
          const updatedActions = actionsSingle.map(action => ({
            ...action,
            action: () => action.action(row.row.id)
          }));
          return <DashboardActions actions={updatedActions} loading={row.loading} />;
        }
      }
    ])
  ];

  // Funções de download
  const downloadSingle = async (registerId) => {
    try {
      const blob = await GeneratePdf([registerId]);
      if (blob) {
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `informe_${registerId}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        throw new Error('Nenhum blob válido recebido para download.');
      }
    } catch (error) {
      console.error('Erro ao baixar o registro:', error);
      toast.error("Erro ao gerar o PDF: " + error.message);
    }
  };

  const downloadMassByUploadId = async (uploadId) => {
    try {
      const result = await ReadAllByUploadId(uploadId);
      const ids = result.data.map(row => row.id);

      if (ids.length > 0) {
        const blob = await GeneratePdf(ids);
        if (blob) {
          const downloadUrl = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download', `informes_${uploadId}.pdf`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          throw new Error('Nenhum blob válido recebido para download.');
        }
      } else {
        toast.error("Nenhum registro encontrado para download.");
      }
    } catch (error) {
      console.error('Erro ao baixar os registros:', error);
      toast.error("Erro ao gerar o PDF: " + error.message);
    }
  };

  // Função para processar os registros
  const parseRegister = item => ({
    ...item,
    date: parseDatestringUSBR(item.createdAt),
    action: () => user?.isManager ? navigate(`dashboard/revenues/form/${item.uploadId}`) : null,
    uploadId: item.uploadId,
    id: item.id,
    companyId: currentCompany?.id
  });

  // Inicialização e carregamento dos dados
  const init = async () => {
    setLoading(true);
    try {
      if (user?.isManager) {
        const incomeResult = await ReadAll(currentCompany?.id);
        if (incomeResult && !exposeStrapiError(incomeResult)) {
          const normalIncomeRegister = normalizeStrapiList(incomeResult) || [];
          const groupedByUploadId = normalIncomeRegister.reduce((acc, item) => {
            if (!acc[item.uploadId]) {
              acc[item.uploadId] = item;
            }
            return acc;
          }, {});

          const consolidatedIncomeRegisters = Object.values(groupedByUploadId)
            .map(parseRegister)
            .map(register => ({
              ...register,
              createdAt: moment(register.createdAt).format("DD/MM/YYYY HH:mm")
            }));

          setRows(consolidatedIncomeRegisters);
        }
      } else {
        const employee = await ReadEmployeeByUserId(user?.id);
        const employeeId = employee.data[0]?.id;
        const incomeResult = await ReadAllForEmployee(employeeId);
        
        if (incomeResult && !exposeStrapiError(incomeResult)) {
          const normalIncomeRegister = normalizeStrapiList(incomeResult) || [];
          const consolidatedIncomeRegisters = normalIncomeRegister
            .map(parseRegister)
            .map(register => ({
              ...register,
              createdAt: moment(register.createdAt).format("DD/MM/YYYY HH:mm"),
              name: register.data?.nome || '',
              cpf: register.data?.cpf || ''
            }));

          setRows(consolidatedIncomeRegisters);
        }
      }
    } catch (error) {
      console.error('Erro ao carregar informes:', error);
      toast.error("Erro ao carregar os informes de rendimento");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (currentCompany?.id) {
      init();
    }
  }, [currentCompany]);

  return (
    <DashboardCardTabs
      title={"Informes"}
      actions={actions}
      tabs={[{ title: "Cadastros" }]}
      active={activeTab}
      setActive={setActiveTab}
    >
      <DashboardCardTable
        rows={rows}
        columns={columns}
        searchExpression={searchExpression}
        loading={loading}
      />
    </DashboardCardTabs>
  );
}
