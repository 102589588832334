import axios from 'axios'
import { ReadObject } from './storage'

const ENDPOINTS = {
	'localhost': 'http://localhost:1337/api',
	'assepontoholerite.com.br': 'https://api.assepontoholerite.com.br/api',
	'134.209.65.141': 'http://157.245.141.68/api'
}

const envEndpoint = () => {
	return (
		Object.keys(ENDPOINTS)
			.filter(fit => `${window.location.origin}`.indexOf(fit) !== -1)
			.map(key => ENDPOINTS[key])[0]
	) || ENDPOINTS['assepontoholerite.com.br']
}

export const API_ENDPOINT = envEndpoint()

export const GetHeaders = async authenticated => {
	const headers = { 'Content-Type': 'application/json' }
	const authentication = await ReadObject('authentication')
	
	console.log('=== Debug GetHeaders ===');
	console.log('authenticated param:', authenticated);
	console.log('authentication object:', authentication);
	
	if (authenticated && authentication?.jwt) {
		// Verificar se o token começa com 'Bearer '
		const token = authentication.jwt.startsWith('Bearer ') 
			? authentication.jwt 
			: `Bearer ${authentication.jwt}`;
			
		headers.Authorization = token;
		
		console.log('Token final:', token);
		console.log('Headers completos:', headers);
	} else {
		console.log('Sem token de autenticação');
	}
	
	return { headers }
}

// Funções base de API
export const ServerFetch = async (url, options, authenticated, isBlob = false) => {
	const { headers } = await GetHeaders(authenticated);
	try {
		const response = await fetch(url, { ...options, headers });
		if (!response.ok) {
			const errorData = await response.json();
			return { error: errorData.error };
		}

		if (isBlob) {
			return response.blob();
		} else {
			return response.json();
		}
	} catch (error) {
		console.log('ServerFetchError', error);
		return { error: { message: error.message, details: 'Erro desconhecido' } };
	}
};

export const GET = async (path, authenticated = false) => {
	return await ServerFetch(`${API_ENDPOINT}${path}`, { method: 'GET' }, authenticated)
}

export const POST = async (path, body, authenticated = false, isBlob = false) => {
	return await ServerFetch(
		`${API_ENDPOINT}${path}`,
		{ method: 'POST', body: JSON.stringify(body) },
		authenticated,
		isBlob
	);
}

export const PUT = async (path, body, authenticated = false) => {
	return await ServerFetch(
		`${API_ENDPOINT}${path}`,
		{ method: 'PUT', body: JSON.stringify(body) },
		authenticated
	)
}

export const DELETE = async (path, authenticated = false) => {
	return await ServerFetch(
		`${API_ENDPOINT}${path}`,
		{ method: 'DELETE' },
		authenticated
	)
}

// Função genérica para upload de arquivos
export const UploadFile = async (file, forward) => {
	const formData = new FormData()
	formData.append('file', file, file.name)
	
	if (forward) {
		formData.append('type', forward.type)
		formData.append('payload', JSON.stringify(forward.payload))
	}

	const { headers } = await GetHeaders(true)
	delete headers['Content-Type']

	return await ServerFetch(
		`${API_ENDPOINT}/upload`,
		{ method: 'POST', body: formData },
		true
	)
}

// Função para preparar imagens
export const PrepareImageFile = (image) => {
	let btc = window.atob(image.source)
	let btn = new Array(btc.length);
	for (var i = 0; i < btc.length; i++) {
		btn[i] = btc.charCodeAt(i);
	}
	var bta = new Uint8Array(btn);
	let boobs = new Blob([bta], { type: 'image/png' });
	return new File([boobs], `${image.filename}`)
}

// Função para upload de imagens
export const UploadImage = async (file, payload) => {
	const formData = new FormData()
	formData.append('files', file)
	formData.append('type', payload.type)
	formData.append('payload', JSON.stringify(payload.payload))

	const headers = {
		'Authorization': `Bearer ${localStorage.getItem('jwt')}`
	}

	const response = await fetch(`${API_ENDPOINT}/holerite-generate/upload`, {
		method: 'POST',
		body: formData,
		headers
	})

	return await response.json()
}

export const parseStrapiImage = url => {
	return !url ? '' : url.indexOf('://') !== -1 ? url : `${API_ENDPOINT.replace('/api', '')}${url}`
}

// Funções de utilidade
export const ReadAddressesByZipCode = async (zipCode) => {
	try {
		let result = await fetch(`https://viacep.com.br/ws/${zipCode}/json/`);
		return result.json();
	} catch (err) { 
		return false; 
	}
}